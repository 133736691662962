<template>
  <v-container
    fill-height
    fluid
    grid-list-xl
    pb-5
    mb-5
  >
    <v-layout
      white
      wrap
      ma-2
    >
      <material-UserForm
        :loading="loading"
        :user="user"
        @save="updateUser($event)"
      />
      <!-- SNACKBAR -->
      <ui-SnackBarV2
        :text="text"
        :color="color"
        :bottom="bottom"
        :top="top"
        :right="right"
        :left="left"
        :snackbar="snackbar"
      />
    </v-layout>
  </v-container>
</template>

<script>
// Utilities
import SnackBarMixin from '@/mixins/SnackBarMixin'
import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'UserEditForm',

  // components:{Snackbar},
  mixins: [SnackBarMixin],

  data: () => ({
    loading: true,
    user: {}
  }),

  created () {
    this.showLoader()
    this.get_user(this.$route.params.id)
      .then(response => {
        console.log('Users OK', response)
        this.user = response
      })
      .catch(error => {
        console.log('Erreur', error)
        this.snack({
          position: 'bottom',
          align: 'center',
          color: 'error',
          text: 'Obtention de l\'utilisateur impossible : ' + error.message
        })
      })
      .finally(() => {
        this.showLoader()
        this.loading = false
      })
  },

  methods: {
    ...mapActions('user', ['get_user', 'update_user']),
    ...mapMutations('app', ['showLoader']),

    updateUser (users) {
      this.showLoader()
      this.update_user(users)
        .then(response => {
          console.log('EDIT', response)
          this.snack({
            position: 'bottom',
            align: 'center',
            color: 'success',
            text: 'Edition de l\'utilisateur#' + response.id + ' "' + response.name + '" effectué avec succès'
          })
        })
        .catch(error => {
          console.log('Erreur', error)
          this.snack({
            position: 'bottom',
            align: 'center',
            color: 'error',
            text: 'Mise à jour de l\'utilisateur impossible : ' + error.message
          })
        })
        .finally(() => {
          this.showLoader()
        })
    }
  }
}
</script>
